import React from 'react';
import {
  Field,
  FieldRenderProps,
  Form,
  FormElement,
  FormRenderProps,
} from '@progress/kendo-react-form';
import { Input } from '@progress/kendo-react-inputs';
import { Error } from '@progress/kendo-react-labels';
import { Button } from '@progress/kendo-react-buttons';

const emailValidator = (value: string) => (/\S+@\S+\.\S+/.test(value) ? '' : 'Please enter a valid email.');
const EmailInput = function (fieldRenderProps: FieldRenderProps) {
  const { validationMessage, visited, ...others } = fieldRenderProps;
  return (
    <div>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Input {...others} />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};

const MyProfile = function () {
  const handleSubmit = (dataItem: { [name: string]: any }) => {
    let a = 1;
    a += 1;
    console.log(JSON.stringify(dataItem, null, 2));
    return a;
  };

  return (
    <Form
      onSubmit={handleSubmit}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ maxWidth: 650 }}>
          <fieldset className="k-form-fieldset">
            <div className="mb-3">
              <Field
                name="firstName"
                component={Input}
                label="First name"
              />
            </div>

            <div className="mb-3">
              <Field name="lastName" component={Input} label="Last name" />
            </div>

            <div className="mb-3">
              <Field
                name="email"
                type="email"
                component={EmailInput}
                label="Email"
                validator={emailValidator}
              />
            </div>
          </fieldset>
          <div className="k-form-buttons">
            <Button
              type="submit"
              disabled={!formRenderProps.allowSubmit}
            >
              Primary
            </Button>
          </div>
        </FormElement>
      )}
    />
  );
};

export default MyProfile;
