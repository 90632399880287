import React from 'react';
import '@progress/kendo-theme-bootstrap/dist/bootstrap-urban.scss';
import 'bootstrap/scss/bootstrap.scss';
import '@aws-amplify/ui-react/styles.css';
import './App.css';
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import DrawerMenu from './navigation/DrawerMenu';
import awsExports from '../aws-exports';

Amplify.configure(awsExports);

const App = function () {
  return (
    <Authenticator
      loginMechanisms={['email']}
      signUpAttributes={[
        'email',
      ]}
      socialProviders={['facebook', 'google']}
    >
      {({ signOut }) => (
        <div className="App">
          <DrawerMenu onSignOut={signOut} />
        </div>
      )}
    </Authenticator>
  );
};

export default App;
