/* eslint-disable */

const awsmobile = {
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_Baewn8F6i",
    "aws_user_pools_web_client_id": "52bm780d0jrpq4g30qbp33ljt2",
    "oauth": {
        domain: 'auth.cicdkons1.reformo.me',
        scope: ['email', 'openid', 'profile'],
        redirectSignIn: 'https://cicdkons1.reformo.me',
        redirectSignOut: 'https://cicdkons1.reformo.me',
        responseType: 'code'
    },
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
      "Google"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL",
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": "8",
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
