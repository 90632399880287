import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import DrawerRouterContainer from './DrawerRouterContainer';
import Dashboard from '../dashboard/Dashboard';
import MyProfile from '../myProfile/MyProfile';
import MyCalendar from '../myCalendar/MyCalendar';

interface DrawerMenuProps {
  onSignOut: any;
}

const DrawerMenu = function (props: DrawerMenuProps) {
  const { onSignOut } = props;

  return (
    <BrowserRouter>
      <DrawerRouterContainer onSignOut={onSignOut}>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/myProfile" element={<MyProfile />} />
          <Route path="/myCalendar" element={<MyCalendar />} />
        </Routes>
      </DrawerRouterContainer>
    </BrowserRouter>
  );
};

export default DrawerMenu;
