import { useLocation, useNavigate } from 'react-router-dom';
import { Drawer, DrawerContent, DrawerSelectEvent } from '@progress/kendo-react-layout';
import { Button } from '@progress/kendo-react-buttons';
import React, { useCallback, useState } from 'react';

const items = [
  {
    text: 'Dashboard', icon: 'k-i-user', selected: true, route: '/',
  },
  { separator: true },
  { text: 'My Profile', icon: 'k-i-user', route: '/myProfile' },
  { text: 'My Calendar', icon: 'k-i-calendar', route: '/myCalendar' },
];

interface DrawerRouterContainerProps {
  onSignOut: any;
  children: any;
}

const DrawerRouterContainer = function ({ children, onSignOut }: DrawerRouterContainerProps) {
  const [expanded, setExpanded] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  const handleDrawerClick = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  const onSelect = useCallback((e: DrawerSelectEvent) => {
    navigate(e.itemTarget.props.route);
  }, [navigate]);

  const setSelectedItem = (pathName: string) => {
    const currentPath: any = items.find((item) => item.route === pathName);
    if (currentPath.text) {
      return currentPath.text;
    }

    return 'Dashboard';
  };

  const selected = setSelectedItem(location.pathname);

  return (
    <div>
      <div className="application-toolbar">
        <Button icon="menu" look="flat" onClick={handleDrawerClick} />
        <span className="mail-box">Reformo</span>
        <Button icon="menu" look="flat" onClick={onSignOut} />
      </div>
      <Drawer
        expanded={expanded}
        position="start"
        mode="push"
        mini
        items={items.map((item) => ({
          ...item,
          selected: item.text === selected,
        }))}
        onSelect={onSelect}
      >
        <DrawerContent>{children}</DrawerContent>
      </Drawer>
    </div>
  );
};

export default DrawerRouterContainer;
