import React from 'react';

const MyCalendar = function () {
  return (
    <div>
      My Calendar
    </div>
  );
};

export default MyCalendar;
