import React from 'react';

const Dashboard = function () {
  return (
    <div>
      Dashboard
    </div>
  );
};

export default Dashboard;
